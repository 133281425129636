.contact_home_modal_container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .contact_home_modal_content {
        background-color: #fff;
        border: 2px solid #ffc107;
        max-width: 400px;
        width: 100%;
        padding: 20px 30px 30px 30px;
        border-radius: 3px;
        box-shadow: 0px 0px 5px rgb(171 165 165 / 27%);
        position: relative;
        animation: home_popup 1s;
        transition: all 1s;

        .close_modal {
            position: absolute;
            top: -22px;
            right: -29px;
            font-size: 30px;
            border: 1px solid #f44336;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            color: #f44336;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 9999;
        }
        .btn {
            display: block;
            margin: auto;
        }
        .form-error-message{
            color: red;
        }
    }
}

@keyframes home_popup {
    from {
        opacity: 0;
        width: 0%;
    }
    to{
        opacity: 1;
        width: 100%;
    }
}

@media (max-width:639.98px){
    .contact_home_modal_container {
        .contact_home_modal_content {
            max-width: 80%;
        }
    }
}

@media (max-width:400px){
    .contact_home_modal_container {
        .contact_home_modal_content {
            padding: 15px 15px 20px 15px;
        }
    }
}