.contact_form_success_messge {
    position: fixed;
    top: 2%;
    right: 0;
    bottom: 0;
    z-index: 99999;
    p{
        margin: 0px;
        font-weight: 500;
    }
    .success_block {
        background-color: #4c1864;
        text-align: center;
        padding: 13px 25px;
        border-radius: 5px;
        color: #fff;
        animation: formSuccess 1s;
    }
    .failed_messge {
        background-color: red;
    }
}
@keyframes formSuccess {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width:320px) {
    .contact_form_success_messge {
        .success_block {
            padding: 20px 50px;
        }
    }
}