.loader_sec {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100vw;
    height: 100vh;
    background-color: #000000a6;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .loader_block {
        background-color: #fff;
        padding: 16px 10px;
        max-width: 300px;
        width: 100%;
        text-align: center;
        border-radius: 4px;
    }
    .loader_text {
        color: #000;
        font-size: 26px;
    }
}