.contact-page {
    .form-error-message {
        color: red;
        margin-top: 7px;
    }
}

/* .contact_form_success_messge {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100vw;
    height: 100vh;
    // background-color: #000000a6;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .success_icon {
        border: 1px solid green;
        color: green;
        font-weight: 800;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    .success_block {
        background-color: #fff;
        text-align: center;
        padding: 20px 70px;
        border-radius: 5px;
        box-shadow: 0px 0px 5px #0000007a;
        button {
            padding: 10px 35px;
            cursor: pointer;
        }
    }
} */

@media (max-width:768px) {
    .contact-page {
        .form-error-message {
            color: red;
            margin-top: 7px;
        }

        iframe {
            height: 250px;
        }
    }
}


/* @media (max-width:320px) {
    .contact_form_success_messge {
        .success_block {
            padding: 20px 50px;
        }
    }
} */