.practice-container{
    .section-area{
        h5{
            text-align: center;
        }
        table{
            border-collapse: collapse;
            width: 100%;
            box-sizing: border-box;
            th{
                background-color: hsla(0, 0%, 50.2%, .1019607843);
            }
            th,td{
                border: 1px solid #7A7A7A;
                text-align: center;
                padding: 0.5em;
            }
            tbody{
                tr{
                    &:hover{
                        background-color: hsla(0, 0%, 50.2%, .1019607843);
                    }
                    td{
                        a {
                            color: #F37224;
                            font-weight: bold;
                        };
                    }
                }
            }
        }
    }
}