.placementList_sec{
    thead tr{
        background-color: #F37224;
        th{
            font-weight: 900;
        }
    }
    tbody tr{
        td:nth-child(n+2){
            min-width: 160px;
        }
    }
    tbody tr{
        td:nth-child(4){
            min-width: auto;
        }
    }
    .placementlist_pagination {
        display: flex;
        .MuiTablePagination-toolbar{
            align-items: center;
            .MuiTablePagination-displayedRows{
                margin-bottom: 0px;
            }
            .MuiTablePagination-selectLabel,.css-16c50h-MuiInputBase-root-MuiTablePagination-select{
                display: none !important;
            }
        }
    }
}